// src/pages/Admin.jsx
import { useState, useEffect } from 'react';
import { Menu, Upload, Button, message, Table, Switch, Typography, Tooltip } from 'antd';
import { UploadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { getAllFilesFunction, uploadFileFunction, getClientsFromMayorista, updateClientSalesVisibility, downloadFileTemplate } from '@/requests/dataService';
import DebitNotesLinker from './debitNoteLinker';
import moment from 'moment';

const { Text } = Typography;

const ExcelStructure = ({ type, downloadTemplate }) => {
  const current_year = moment().year();
  const last_year = current_year - 1;

  const estimatedSalesColumns = [
    {
      title: '2025',
      dataIndex: 'office_name',
      key: 'office_name',
    },
    {
      title: 'ENERO PP',
      dataIndex: 'january_pp',
      key: 'january_pp',
    },
    {
      title: 'FEBRERO PP',
      dataIndex: 'february_pp',
      key: 'february_pp',
    },
    {
      title: 'MARZO PP',
      dataIndex: 'march_pp',
      key: 'march_pp',
    },
    {
      title: 'ABRIL PP',
      dataIndex: 'april_pp',
      key: 'april_pp',
    },
    {
      title: 'MAYO PP',
      dataIndex: 'may_pp',
      key: 'may_pp',
    },
    {
      title: 'JUNIO PP',
      dataIndex: 'june_pp',
      key: 'june_pp',
    },
    {
      title: 'JULIO PP',
      dataIndex: 'july_pp',
      key: 'july_pp',
    },
    {
      title: 'AGOSTO  PP',
      dataIndex: 'august_pp',
      key: 'august_pp',
    },
    {
      title: 'SEPTIEMBRE PP',
      dataIndex: 'september_pp',
      key: 'september_pp',
    },
    {
      title: 'OCTUBRE  PP',
      dataIndex: 'october_pp',
      key: 'october_pp',
    },
    {
      title: 'NOVIEMBRE  PP',
      dataIndex: 'november_pp',
      key: 'november_pp',
    },
    {
      title: 'DICIEMBRE  PP',
      dataIndex: 'december_pp',
      key: 'december_pp',
    },
  ];

  const estimatedSalesData = [
    {
      key: '1',
      office_name: 'MARKETPLACE',
      january_pp: '1000',
      february_pp: '1000',
      march_pp: '1000',
      april_pp: '1000',
      may_pp: '1000',
      june_pp: '1000',
      july_pp: '1000',
      august_pp: '1000',
      september_pp: '1000',
      october_pp: '1000',
      november_pp: '1000',
      december_pp: '1000',
    },
    {
      key: '2',
      office_name: 'MAYORISTA',
      january_pp: '3000',
      february_pp: '3000',
      march_pp: '3000',
      april_pp: '3000',
      may_pp: '3000',
      june_pp: '3000',
      july_pp: '3000',
      august_pp: '1000',
      september_pp: '1000',
      october_pp: '1000',
      november_pp: '1000',
      december_pp: '1000',
    },
  ];

  const dailyEstimatedSalesColumns = [
    {
      title: 'FECHA',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: `NETO ${last_year}`,
      dataIndex: 'last_year_net',
      key: 'last_year_net',
    },
    {
      title: `NETO ${current_year}`,
      dataIndex: 'curr_year_net',
      key: 'curr_year_net',
    },
  ];

  const dailyEstimatedSalesData = [
    {
      key: '1',
      date: '2024-01-01',
      last_year_net: '1000',
      curr_year_net: '2000',
    },
    {
      key: '2',
      date: '2024-01-02',
      last_year_net: '4000',
      curr_year_net: '1500',
    },
  ];

  return (
    <div style={{ marginTop: '20px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Text strong>Estructura del archivo Excel:</Text>
        <Button
          type="primary"
          style={{ marginLeft: '10px' }}
          onClick={() => downloadTemplate(type)}
        >
          Descargar Plantilla
        </Button>
      </div>
      <Table
        columns={type === 'estimated-sales' ? estimatedSalesColumns : dailyEstimatedSalesColumns}
        dataSource={type === 'estimated-sales' ? estimatedSalesData : dailyEstimatedSalesData}
        pagination={false}
        bordered={true}
        style={{ marginTop: '10px' }}
        footer={
          type === 'daily-estimated-sales'
            ? () => (
              <div style={{ display: 'flex', justifyContent: 'left', backgroundColor: 'lightgray', borderRadius: '4px' }}>
                <div
                  style={{
                    display: 'inline-block',
                    borderRight: '1px solid black',
                    borderRadius: '4px',
                    padding: '4px 8px',
                  }}
                >
                  Fabrica Web
                </div>
                <div
                  style={{
                    display: 'inline-block',
                    borderRight: '1px solid black',
                    borderRadius: '4px',
                    padding: '4px 8px',
                  }}
                >
                  COSTANERA
                </div>
                <div
                  style={{
                    display: 'inline-block',
                    borderRight: '1px solid black',
                    borderRadius: '4px',
                    padding: '4px 8px',
                  }}
                >
                  Araucaria
                </div>
              </div>
            )
            : null
        }
      />
    </div>
  );
};

const Admin = () => {
  const [selectedType, setSelectedType] = useState('estimated-sales');
  const [fileList, setFileList] = useState([]);
  const [clientList, setClientList] = useState([]);

  useEffect(() => {
    fetchClientList();
    fetchFileList(selectedType);
  }, []);

  const handleMenuClick = (e) => {
    setSelectedType(e.key);
    if (e.key !== 'clients') {
      fetchFileList(e.key);
    }
  };

  const fetchFileList = (type) => {
    getAllFilesFunction(type)
      .then(response => {
        const sortedResponse = response.sort((a, b) => new Date(b.created) - new Date(a.created));
        setFileList(sortedResponse);
      })
      .catch(error => {
        console.error('There was an error fetching the file list!', error);
      });
  };

  const fetchClientList = () => {
    getClientsFromMayorista()
      .then(response => {
        setClientList(response);
      })
      .catch(error => {
        console.error('There was an error fetching the client list!', error);
      });
  };

  const handleUpload = async (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
      fetchFileList(selectedType);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const downloadTemplate = async (type) => {
    try {
      const response = await downloadFileTemplate(type);
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${type}_template.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading file template:', error);
      message.error('Failed to download file template');
    }
  };

  const customUpload = async ({ file }) => {
    uploadFileFunction(file, selectedType)
      .then(() => {
        console.log('File uploaded successfully');
        handleUpload({ file: { status: 'done', name: file.name } });
      })
      .catch((error) => {
        console.error('File upload failed:', error);
        handleUpload({ file: { status: 'error', name: file.name } });
      });
  };

  const uploadProps = {
    customRequest: customUpload,
    onChange: handleUpload,
  };

  const handleSwitchChange = (checked, client) => {
    const updatedClientList = clientList.map(c =>
      c.client_name === client.client_name ? { ...c, considered_sale: checked } : c
    );
    setClientList(updatedClientList);
  };

  const handleUpdateClientVisibility = () => {
    const clientsData = clientList.map(client => ({
      client_name: client.client_name,
      show_sales: client.considered_sale
    }));

    updateClientSalesVisibility({ clients: clientsData })
      .then(() => {
        message.success('Client sales visibility updated successfully');
      })
      .catch(error => {
        console.error('Error updating client sales visibility:', error);
        message.error('Failed to update client sales visibility');
      });
  };

  const fileColumns = [
    {
      title: 'File Name',
      dataIndex: 'file_name',
      key: 'file_name',
    },
    {
      title: 'Upload Date',
      dataIndex: 'created',
      key: 'created',
      render: (text) => moment(text).format('DD-MM-YYYY'),
    },
    {
      title: 'Status',
      dataIndex: 'get_status_display',
      key: 'get_status_display',
      render: (text, record) => (
        <>
          {text}
          {record.get_status_display === 'Formato inválido' && (
            <Tooltip title={record.error_message}>
              <InfoCircleOutlined style={{ marginLeft: '4px', color: 'red' }} />
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  const clientColumns = [
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
    },
    {
      title: 'Consider Sales',
      dataIndex: 'considered_sale',
      key: 'considered_sale',
      render: (text, record) => (
        <Switch
          checked={record.considered_sale}
          onChange={(checked) => handleSwitchChange(checked, record)}
        />
      ),
    },
  ];

  return (
    <div style={{ padding: '20px' }}>
      <h1>Admin Panel</h1>
      <Menu onClick={handleMenuClick} selectedKeys={[selectedType]} mode="horizontal">
        <Menu.Item key="estimated-sales">Archivos de ventas estimadas</Menu.Item>
        <Menu.Item key="daily-estimated-sales">Archivos de ventas diarias</Menu.Item>
        <Menu.Item key="stock">Archivos de Stock</Menu.Item>
        <Menu.Item key="clients">Considerar Clientes Mayorista</Menu.Item>
        <Menu.Item key="debit-notes">Vincular Notas de Débito</Menu.Item>
      </Menu>
      <div style={{ marginTop: '20px' }}>
        {selectedType === 'debit-notes' ? <DebitNotesLinker /> :
          selectedType !== 'clients' ? (
            <>
              <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />}>Upload {selectedType.replace('-', ' ').toUpperCase()} File</Button>
              </Upload>
              <Table dataSource={fileList} columns={fileColumns} rowKey="id" style={{ marginTop: '20px' }} pagination={{ pageSize: 4 }} />
            </>
          ) : (
            <>
              <Table dataSource={clientList} columns={clientColumns} rowKey="client_name" style={{ marginTop: '20px' }} pagination={false} />
              <Button type="primary" onClick={handleUpdateClientVisibility} style={{ marginTop: '20px' }}>
                Actualizar Visibilidad de Ventas de Clientes
              </Button>
            </>
          )}
      </div>
      {(selectedType === 'estimated-sales' || selectedType === 'daily-estimated-sales') && (
        <div style={{ marginTop: '20px' }}>
          <ExcelStructure type={selectedType} downloadTemplate={downloadTemplate} />
        </div>
      )}
    </div>
  );
};

export default Admin;
