import React, { useState, useEffect } from 'react';
import { Table, Button, Select, message, Spin, Input, Tooltip, Modal } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { getDebitNotesWoRelatedCreditNote, getAllCreditNotesBsaleIds, linkDebitNoteToCreditNote } from '@/requests/dataService';

const DebitNotesLinker = () => {
    const [debitNotes, setDebitNotes] = useState([]);
    const [creditNotes, setCreditNotes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [linking, setLinking] = useState(false);
    const [selectedCreditNote, setSelectedCreditNote] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({ debitNoteId: '', creditNoteId: '' });
    const [messageApi, contextHolder] = message.useMessage();

    const successMessage = (message) => {
        messageApi.success(message);
    }

    const errorMessage = (message) => {
        messageApi.error(message);
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                await Promise.all([fetchDebitNotes(), fetchCreditNotes()]);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const fetchDebitNotes = async () => {
        try {
            const response = await getDebitNotesWoRelatedCreditNote();
            setDebitNotes(response);
        } catch (error) {
            console.error('Error fetching debit notes:', error);
        }
    };

    const fetchCreditNotes = async () => {
        try {
            const response = await getAllCreditNotesBsaleIds();
            setCreditNotes(response);
        } catch (error) {
            console.error('Error fetching credit notes:', error);
        }
    };

    const showModal = (debitNote, creditNote) => {
        setModalContent({ debitNote, creditNote });
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        setLinking(true);
        const { debitNote, creditNote } = modalContent;
        const debitNoteUuid = debitNotes.find(note => note.bsale_id === debitNote.bsale_id && note.product__sku === debitNote.product__sku).uuid;

        try {
            await linkDebitNoteToCreditNote(debitNoteUuid, creditNote.bsale_id);
            successMessage(`Nota de débito ${debitNote.document_code} vinculada con la nota de crédito ${creditNote.document_code} exitosamente.`);
            await fetchDebitNotes(); // Refresh the list and wait for it to complete
        } catch (error) {
            console.error('Error linking debit note to credit note:', error);
            errorMessage('Hubo un error al vincular la nota de débito con la nota de crédito.');
        } finally {
            setLinking(false);
            setIsModalOpen(false);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        const { debitNote } = modalContent;
        const debitNoteUuid = debitNotes.find(note => note.bsale_id === debitNote.bsale_id && note.product__sku === debitNote.product__sku).uuid;
        setSelectedCreditNote(prev => ({ ...prev, [debitNoteUuid]: null }));
    };

    return (
        <div>
            {contextHolder}
            <h2>Notas de Debito sin Nota de Credito Relacionada</h2>
            <Spin spinning={loading || linking}>
                <Table
                    dataSource={debitNotes}
                    columns={[
                        {
                            title: 'BSale ID', dataIndex: 'bsale_id', key: 'bsale_id',
                            sorter: (a, b) => a.bsale_id - b.bsale_id,
                            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                                <div style={{ padding: 8 }}>
                                    <Input
                                        placeholder={`Search BSale ID`}
                                        value={selectedKeys[0]}
                                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                                        onPressEnter={confirm}
                                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                                    />
                                    <Button
                                        type="primary"
                                        onClick={confirm}
                                        icon={<SearchOutlined />}
                                        size="small"
                                        style={{ width: 90, marginRight: 8 }}
                                    >
                                        Buscar
                                    </Button>
                                    <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
                                        Reset
                                    </Button>
                                </div>
                            ),
                            onFilter: (value, record) => record.bsale_id.toString().toLowerCase().includes(value.toLowerCase()),
                        },
                        {
                            title: 'BSale Code', dataIndex: 'document_code', key: 'document_code',
                            sorter: (a, b) => a.document_code - b.document_code,
                            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                                <div style={{ padding: 8 }}>
                                    <Input
                                        placeholder={`Search BSale Code`}
                                        value={selectedKeys[0]}
                                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                                        onPressEnter={confirm}
                                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                                    />
                                    <Button
                                        type="primary"
                                        onClick={confirm}
                                        icon={<SearchOutlined />}
                                        size="small"
                                        style={{ width: 90, marginRight: 8 }}
                                    >
                                        Buscar
                                    </Button>
                                    <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
                                        Reset
                                    </Button>
                                </div>
                            ),
                            onFilter: (value, record) => record.document_code.toString().toLowerCase().includes(value.toLowerCase()),
                        },
                        {
                            title: 'Fecha de Generacion', dataIndex: 'generation_date', key: 'generation_date',
                            sorter: (a, b) => new Date(a.generation_date) - new Date(b.generation_date)
                        },
                        { title: 'SKU', dataIndex: 'product_sku', key: 'product_sku', sorter: (a, b) => a.product_sku.localeCompare(b.product_sku), },
                        { title: 'Cliente', dataIndex: 'client_name', key: 'client_name', sorter: (a, b) => a.client_name.localeCompare(b.client_name), },
                        {
                            title: 'Oficina',
                            key: 'office_name',
                            render: (text, record) => record.office_name === 'marketplace' ? `${record.marketplace_office} (MKP)` : record.office_name,
                            sorter: (a, b) => a.office_name.localeCompare(b.office_name),
                        },
                        {
                            title: 'Linkear Nota de Credito',
                            key: 'link_credit_note',
                            render: (text, record) => (
                                <Select
                                    showSearch
                                    placeholder="Select Credit Note"
                                    style={{ width: 300 }}
                                    value={selectedCreditNote[record.uuid] || undefined}
                                    onChange={(value) => {
                                        setSelectedCreditNote(prev => ({ ...prev, [record.uuid]: creditNotes.find(note => note.uuid === value).document_code }));
                                        showModal(record, creditNotes.find(note => note.uuid === value));
                                    }}
                                    options={creditNotes.map(note => {
                                        return {
                                            value: note.uuid,
                                            label: (
                                                <Tooltip
                                                    title={
                                                        <div>
                                                            <div>- BSale ID: {note.bsale_id}</div>
                                                            <div>- BSale Code: {note.document_code}</div>
                                                            <div>- Cliente: {note.client_name}</div>
                                                            <div>- SKU: {note.product__sku}</div>
                                                            <div>- Fecha: {note.generation_date}</div>
                                                        </div>
                                                    }
                                                >
                                                    {`${note.document_code} - ${note.client_name}`}
                                                </Tooltip>
                                            )
                                        }
                                    })}
                                    filterOption={(input, option) =>
                                        option.label.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                />
                            ),
                        },
                    ]}
                    rowKey="uuid"
                />
            </Spin>
            <Modal
                title="Confirmación"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <p>¿Está seguro de que desea vincular la nota de débito {modalContent.debitNote.document_code} con la nota de crédito {modalContent.creditNote.document_code}? Esta acción no se puede deshacer.</p>
            </Modal>
        </div>
    );
};

export default DebitNotesLinker;